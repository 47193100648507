export default {
  esriGotoOptions: {
    animate: true,
    duration: 3000
  },
  parcelBoundaryPolygon: {
    type: "simple-fill",
    color: [25, 103, 169, 0.1],
    style: "solid",
    outline: {
      color: "#1967a9",
      width: 2
    }
  },
  parcelMarker: {
    type: "picture-marker",
    url: "/images/propertyintel-parcel-marker.svg",
    width: "32px",
    height: "48px"
  },
  mapDefaultOptions: {
    center: [153.0236, -27.4689], // Brisbane City Hall Coordinates
    zoom: 15,
    popup: {
      actions: [],
      autoOpenEnabled: false,
      dockEnabled: true,
      dockOptions: {
        buttonEnabled: false,
        breakpoint: false,
        position: "top-right"
      }
    }
  }
};
