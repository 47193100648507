<template>
  <div id="property-map" ref="property-map" class="property-map"></div>
</template>

<script>
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import Point from "@arcgis/core/geometry/Point";
import Graphic from "@arcgis/core/Graphic";
import Polygon from "@arcgis/core/geometry/Polygon";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import MapService from "@/services/MapService";
import MapConstants from "@/constants/MapConstants";

import BingMapsLayer from "@arcgis/core/layers/BingMapsLayer";
import { merge, uniqBy } from "lodash";
import { mapState } from "vuex";

export default {
  props: {
    parcelDetails: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    view: null,
    map: null,
    graphicsLayer: null,
  }),
  computed: mapState({
    currentParcel: state => state.address.address.currentParcel,
  }),
  watch: {
    parcelDetails: function() {
      this.clearSelectedAddressDetails();
      if (this.parcelDetails) {
        this.displayParcel(this.parcelDetails);
      }
    },
  },
  async mounted() {
    // create a basemap from a dynamic mapserver
    const bing = new BingMapsLayer({
      style: "aerial",
      key: process.env.VUE_APP_BING_APIKEY,
    });

    this.map = new Map({
      basemap: {
        baseLayers: [bing],
      },
    });

    // eslint-disable-next-line no-unused-vars
    this.view = new MapView(
      merge(
        {
          container: this.$refs["property-map"],
          map: this.map,
        },
        MapConstants.mapDefaultOptions
      )
    );

    this.view.ui.move("zoom", {
      position: "top-right",
    });

    this.graphicsLayer = new GraphicsLayer();
    this.map.add(this.graphicsLayer);
  },
  methods: {
    clearSelectedAddressDetails() {
      this.addressDetails = null;

      // clear all the graphics
      this.graphicsLayer.removeAll();
    },

    displayParcel() {
      const parcel = this.currentParcel;
      if (parcel) {
        this.fetchLotParcelValues(
          parcel.Parcel.Lot,
          parcel.Parcel.Plan,
          parcel.Geocode.Longitude,
          parcel.Geocode.Latitude
        );
      }
    },

    async fetchLotParcelValues(lot, plan, longitude, latitude) {
      // Convert all 9999 lot numbers to 0
      // to match data between geocoding service and cadastral parcel feature layer service
      if (lot === "9999") {
        lot = "0";
      }

      let parcel = await MapService.fetchPropertyParcelDetails(lot, plan);
      if (parcel.features.length) {
        await this.navigateAndDisplayParcel(parcel, longitude, latitude);
      } else {
        // parcel details not found, could be a shared lot plan
        // try to look for lot 0 of parcel
        parcel = await MapService.fetchPropertyParcelDetails("0", plan);
        if (parcel.features.length) {
          await this.navigateAndDisplayParcel(parcel, longitude, latitude);
        } else {
          this.$emit("parcel-details-not-found");
        }
      }
    },

    async navigateAndDisplayParcel(parcel, longitude, latitude) {
      const lotArea = this.calculateLotArea(parcel.features);

      this.navigateToLocation(
        longitude,
        latitude,
        this.computeMapZoom(lotArea)
      );
      await this.drawParcelBoundary(parcel);
      this.drawParcelMarker(longitude, latitude);
      this.$emit("parcel-map-render-complete");
    },

    navigateToLocation(longitude, latitude, zoom) {
      const esriPoint = new Point({
        latitude,
        longitude,
      });

      this.view.goTo(
        {
          target: esriPoint,
          zoom,
        },
        MapConstants.esriGotoOptions
      );
    },

    async drawParcelBoundary(parcel) {
      const geometries = [];

      for (const lotPlan of parcel.features) {
        const parcelBoundary = new Polygon({
          rings: lotPlan.geometry.rings,
          spatialReference: parcel.spatialReference,
        });

        const parcelBoundaryGraphic = new Graphic({
          geometry: parcelBoundary,
          symbol: MapConstants.parcelBoundaryPolygon,
        });

        this.graphicsLayer.add(parcelBoundaryGraphic);
        this.map.add(this.graphicsLayer);
        geometries.push(parcelBoundaryGraphic.geometry);
      }

      const attr = parcel.features[0].attributes;
      const lotArea = this.calculateLotArea(parcel.features);

      // const tenure = this.calculateTenure(parcel.features);
      let tenure = attr.TENURE_DESC;
      if (attr.TENURE_DESC === null || attr.TENURE_DESC === undefined || attr.TENURE_DESC === "") { 
        // attempt to go get it from lot 0 of current plan
        const zeroParcel = await MapService.fetchPropertyParcelDetails("0", attr.PLAN_);
        const zeroAttr = zeroParcel.features[0].attributes;
        const zeroTenure = zeroAttr.TENURE_DESC; 
        tenure = zeroTenure;
      }

      this.setAdditionalParcelFeatures({
        landTenure: tenure,
        lot: attr.LOT,
        lotArea,
        plan: attr.PLAN_, // OR attr.PLAN
        geometries,
      });
    },

    calculateLotArea(lotPlans) {
      const lotTitleAreas = lotPlans.map(
        feature => feature.attributes.LOT_TITLE_AREA
      );
      const maxLotTitleArea = Math.max(0, ...lotTitleAreas);

      if (maxLotTitleArea > 0) {
        return maxLotTitleArea;
      }

      const totalLotArea = uniqBy(lotPlans, "feature.attributes.PROPERTY_ID")
        .map(feature => feature.attributes.LOT_AREA)
        .reduce((totalLotArea, lotArea) => totalLotArea + lotArea, 0);

      return totalLotArea;
    },

    computeMapZoom(lotArea) {
      let zoom = 18; // default zoom for parcels with no area
      if (lotArea < 300) {
        zoom = 20;
      } else if (lotArea >= 300 && lotArea < 1000) {
        zoom = 19;
      } else if (lotArea >= 1000 && lotArea < 20000) {
        zoom = 18;
      } else if (lotArea >= 20000 && lotArea < 50000) {
        zoom = 17;
      } else if (lotArea >= 50000 && lotArea < 1000000) {
        zoom = 16;
      } else if (lotArea >= 1000000){
        zoom = 15;
      }
      return zoom;
    },

    drawParcelMarker(longitude, latitude) {
      const marker = {
        type: "point",
        longitude,
        latitude,
      };

      const markerGraphic = new Graphic({
        geometry: marker,
        symbol: MapConstants.parcelMarker,
      });
      this.graphicsLayer.add(markerGraphic);
    },

    async setAdditionalParcelFeatures(parcelFeatures) {
      await this.$store.dispatch("setAdditionalParcelFeatures", parcelFeatures);
      this.$store.dispatch("setCanGenerateReport", true);
    },
  },
};
</script>

<style>
.property-map {
  position: relative;
  width: 100%;
}

.property-map:before {
  content: "";
  display: block;
  padding-top: 80%;
}
</style>
